<template>
  <BreadCrumb PageTitle="Apertura y cierre por d&iacute;a" Subtitle="Reportes"></BreadCrumb>
  <OpenClose></OpenClose>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import OpenClose from "@/components/Reports/OpenClose.vue"

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, OpenClose}
}
</script>

<style scoped>

</style>
