<template>
  <BreadCrumb PageTitle="Ventas de servicios extras por per&iacute;odos" Subtitle="Reportes"></BreadCrumb>
  <ExtraByDateComponent></ExtraByDateComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import ExtraByDateComponent from "@/components/Reports/ExtraByDateComponent.vue"

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, ExtraByDateComponent}
}
</script>

<style scoped>

</style>
