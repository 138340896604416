<template>
  <BreadCrumb PageTitle="Expiración de GPS" Subtitle="Reportes"></BreadCrumb>
  <CarExpireGPSComponent></CarExpireGPSComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import CarExpireGPSComponent from "@/components/Reports/CarExpireGPSComponent.vue";

export default {
  name: "ExpiracionGPS",
  components: {BreadCrumb, MarcaList, CarExpireGPSComponent}
}
</script>

<style scoped>

</style>
