<template>
  <BreadCrumb PageTitle="Ventas por per&iacute;odos" Subtitle="Reportes"></BreadCrumb>
  <AmountByDateComponent></AmountByDateComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import AmountByDateComponent from "@/components/Reports/AmountByDateComponent.vue"

export default {
  name: "Marcas",
  components: {BreadCrumb, MarcaList, AmountByDateComponent}
}
</script>

<style scoped>

</style>
