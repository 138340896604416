<template>
  <BreadCrumb PageTitle="Expiración de Circulación" Subtitle="Reportes"></BreadCrumb>
  <CarExpireCirculationComponent></CarExpireCirculationComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import CarExpireCirculationComponent from "@/components/Reports/CarExpireCirculationComponent.vue";

export default {
  name: "ExpiracionCirculation",
  components: {BreadCrumb, MarcaList, CarExpireCirculationComponent}
}
</script>

<style scoped>

</style>
