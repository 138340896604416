<template>
  <BreadCrumb PageTitle="Listado de clientes pendientes por autorizar" Subtitle="Seguridad"></BreadCrumb>
  <AuthorizeClientsComponent></AuthorizeClientsComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import AuthorizeClientsComponent from "@/components/Seguridad/AuthorizeClientsComponent.vue";

export default {
  name: "Clientes",
  components: {BreadCrumb, MarcaList, AuthorizeClientsComponent}
}
</script>

<style scoped>

</style>
