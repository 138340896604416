<template>
  <div class="Cargando">
    <div v-show="loading || cargando" :class="['modal_cargando', { 'show': loading || cargando }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text" v-model="search"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar reserva"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <VueDatePicker v-model="search_date" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                       teleport-center
                       format="dd/MM/yyyy hh:mm aa"
                       time-picker-inline placeholder="Seleccione la fecha"/>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="openNuevo"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Nueva reserva
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
        <button
            @click="exportToExcel"
            class="ms-3 bg-success position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Exportar
          <i class="flaticon-download position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Estado
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              N&uacute;mero de reserva
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Cliente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Categor&iacute;a
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Veh&iacute;culo
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Procedencia
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Fecha de salida
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Fecha de regreso
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Creado por
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Total
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Pendiente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Pagado
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <div class="ms-3" v-if="item.imagen!==''">
                <img class="rounded-circle img-categoria cursor-pointer"
                     :src="getImageUrl(item.categoria.imagen)">
              </div>
              <div class="ms-3" v-else>
                <img class="rounded-circle img-categoria cursor-pointer"
                     src="@/assets/images/autos/no-photo.jpg">
              </div>
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.estado }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.reservaNo }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.cliente ? item.cliente.user.email : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.categoria.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary text-start">
              <button v-show="item.estado!=='Contratada' && item.estado!=='Cancelada' && item.estado!=='Cerrada'" data-bs-toggle="tooltip" @click="openCar(item)"
                      data-bs-placement="top"
                      style="padding: 0.4rem"
                      title="Cambiar vehículo" data-container="body" data-animation="true"
                      class="btn btn-outline-success text-success me-1">
                <i class="flaticon-compare d-flex justify-content-center align-items-center "></i></button>
              {{
                item.vehiculoReservadoSet.length > 0 ? item.vehiculoReservadoSet[0].vehiculo.noEconomico : "Sin vehículo"
              }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.procedencia.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearFecha(item.fechaSalida) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearFecha(item.fechaRegreso) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.createdBy ? item.createdBy.email : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              $ {{
                formatearNumero(parseFloat(item.total) - parseFloat(item.totalPromocion))
              }} MXN
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              $ {{ getMontoPendiente(item.total, item.montoAPagar, item.totalPromocion) }} MXN
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <span class="total">$ {{
                  formatearNumero(item.montoAPagar)
                }} MXN</span>
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-show="item.estado!=='Contratada' & item.estado!=='Cancelada' & item.estado!=='Cerrada'" data-bs-toggle="tooltip"
                        @click="openUpdate(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar reserva" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="Details(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver detalles de la reserva" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip" @click="generateReportPDF(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Imprimir o descargar reserva" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-printer d-flex justify-content-center align-items-center "></i></button>
                <button v-show="item.estado!=='Contratada' & item.estado!=='Cancelada' & item.estado!=='Cerrada'" data-bs-toggle="tooltip"
                        @click="createContrato(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Crear contrato" data-container="body" data-animation="true"
                        class="btn text-success">
                  <i class="flaticon-form  d-flex justify-content-center align-items-center "></i></button>
                <button data-bs-toggle="tooltip"
                        @click="cancelarReserva(item.id)"
                        style="padding: 0.8rem"
                        data-bs-placement="top" v-if="item.estado!=='Cancelada'&& item.estado!=='Contratada'"
                        title="Cancelar reserva" data-container="body" data-animation="true"
                        class="btn text-danger">
                  <i class="flaticon-close  d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">

      </div>
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
          <option :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="hasPreviousPage===false"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="hasNextPage===false"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <h4 class="text-start fw-bold">
            {{ accion === 'nuevo' ? 'Nueva reserva' : 'Actualizar reserva' }}
          </h4>
          <div class="steps-container">
            <div v-for="step in steps" :key="step" class="step" :class="{ active: currentStep === step }">
              {{ step }}
            </div>
            <div class="step-close">
              <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
            </div>
          </div>
        </div>
        <div v-if="ventana===0">
          <div class="row mb-3 text-start">
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 procedencias">
              <label class="form-label ms-1">Procedencia de la reserva<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="procedencia" :class="error_procedencia?'is-invalid':'border-success'" required
                      class="form-control shadow-none rounded-0 text-black" :disabled="accion!=='nuevo'">
                <option disabled :value="0">Seleccione la procedencia de la reserva</option>
                <option v-for="item in procedenciaByName" :key="item.id" :value="item.id">
                  {{ item.nombre }}
                </option>
              </select>
              <div v-if="error_procedencia" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 sucursal_salida">
              <label class="form-label ms-1">Sucursal de recogida<span class="text-danger fw-bold"> *</span></label>
              <select v-model="sucursal_recogida" :class="error_sucursal_recogida?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black" required>
                <option value="0" disabled>Seleccione una sucursal</option>
                <option v-for="item in sucursalesApp" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
              <div v-if="error_sucursal_recogida" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 sucursal_regreso">
              <label class="form-label ms-1">Sucursal de entrega<span class="text-danger fw-bold"> *</span></label>
              <select v-model="sucursal_entrega" :class="error_sucursal_entrega?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black" required>
                <option value="0" disabled>Seleccione una sucursal</option>
                <option v-for="item in sucursalesApp" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
              <div v-if="error_sucursal_entrega" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 fecha_salida">
              <label class="form-label ms-1">Fecha de salida<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_salida" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             teleport-center :state="!error_fecha_salida"
                             :min-date="new Date()"
                             required
                             format="dd/MM/yyyy hh:mm aa"
                             time-picker-inline placeholder="Seleccione la fecha"/>
              <div v-if="error_fecha_salida" class="invalid-feedback" style="display: block !important;">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 fecha_regreso">
              <label class="form-label ms-1">Fecha de regreso<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_regreso"
                             auto-apply
                             locale="es"
                             :dark="this.$store.state.isDarkMode"
                             :min-date="minDateValidate"
                             teleport-center
                             :state="!error_fecha_regreso"
                             format="dd/MM/yyyy hh:mm aa"
                             required
                             time-picker-inline placeholder="Seleccione la fecha"/>
              <div v-if="error_fecha_regreso" class="invalid-feedback" style="display: block !important;">
                Campo no puede estar vac&iacute;o y mayor que la fecha de salida
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 Dias">
              <label class="form-label ms-1">Días<span class="text-danger fw-bold"> *</span></label>
              <input :class="dias>0?'border-success':'is-invalid'" v-model="dias" readonly
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 categoria">
              <label class="form-label ms-1">Categor&iacute;a<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-if="accion==='nuevo'" v-model="categoria"
                      :class="error_categoria?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option :value="null" disabled>Seleccione una categor&iacute;a</option>
                <option v-for="item in categoriasByName" :key="item.id" :value="item">{{ item.name }} $
                  {{ item.precio }} MXN
                </option>
              </select>
              <input v-else type="text" v-model="category_update" class="form-control shadow-none rounded-0 text-black"
                     disabled>
              <div v-if="error_categoria" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 Precio_Diario">
              <label class="form-label ms-1">Tarifa diaria<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="accion==='update'"
                     v-model="tarifa_diaria"
                     class="form-control shadow-none rounded-0 text-black"
              />
              <div v-if="error_diario" class="invalid-feedback">
                La tarifa diaria no puede ser 0
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 protecciones">
              <label class="form-label ms-1">Protecci&oacute;n</label>
              <select :disabled="dias===0" v-model="proteccion" :class="error_proteccion?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black" required>
                <option :value="0">Protecci&oacute;n B&aacute;sica</option>
                <option v-for="item in proteccionesApp" :key="item" :value="item.id"
                        v-show="item.name.includes(this.category_name)">{{ item.name }} $
                  {{ item.tarifa }} MXN
                </option>
              </select>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 servicios">
              <label class="form-label ms-1">Servicios extras</label>
              <select :disabled="dias===0" class="form-control shadow-none rounded-0 text-black w-321"
                      v-model="servicios"
                      :class="servicios_extras.length > 0?'border-success':''" required>
                <option value="0" disabled>Seleccione los servicios extras</option>
                <option v-for="item in serviciosExtrasApp" :key="item.id" :value="item.id"
                        :disabled="servicios_extras.includes(parseInt(item.id))"
                        v-show="item.name.toLowerCase().includes(this.category_name.toLowerCase()) || item.visibleAll">
                  {{ item.name }} $ {{ item.precio }} MXN {{ item.diario ? "/ Pago diario" : "/ Único pago " }}
                </option>
              </select>
              <div class="selected-items" v-if="servicios_extras.length>0">
                <div class="selected-item" v-for="item in servicios_extras">
                  <span> {{
                      serviciosExtrasApp.find(e => parseInt(e.id) === item).name
                    }} $ {{ serviciosExtrasApp.find(e => parseInt(e.id) === item).precio }} MXN</span>
                  <span class="remove-item"
                        @click="()=>Quitar_extra(serviciosExtrasApp.find(e=>parseInt(e.id)===item).id)">x</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_Dos" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-if="ventana===1">
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 correo">
              <label class="form-label ms-1">Correo<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_email?'is-invalid':'border-success'" v-model="email" required
                     class="form-control shadow-none rounded-0 text-black" @input="autocomplete">
              <ul v-if="showSubmenu" class="position-absolute z-index2 bg-white text-dark p-2" style="z-index:10">
                <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                    :key="item.id"
                    @click="selectItem(item)">{{ item?.user?.email }}
                </li>
              </ul>
              <div v-if="error_email" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 nombre">
              <label class="form-label ms-1">Nombre<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_nombre?'is-invalid':'border-success'" v-model="nombre" required
                     class="form-control shadow-none rounded-0 text-black">
              <div v-if="error_nombre" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 apellidos">
              <label class="form-label ms-1">Apellidos<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_apellidos?'is-invalid':'border-success'" v-model="apellidos" required
                     class="form-control shadow-none rounded-0 text-black">
              <div v-if="error_apellidos" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 telefono">
              <label class="form-label ms-1">Tel&eacute;fono<span class="text-danger fw-bold"> *</span></label>
              <MazPhoneNumberInput
                  v-model="telefono"
                  v-model:country-code="codigo"
                  no-radius
                  class="w-100"
                  :preferred-countries="['MX', 'US', 'CU']"
                  :error="error_telefono"
                  :translations="{
                    countrySelector: {
                      placeholder: 'Código de país',
                      error: 'Seleccione un país',
                      searchPlaceholder: 'Buscar un país',
                    },
                    phoneInput: {
                      placeholder: 'Número de teléfono',
                      example: 'Ejemplo:',
                    },
                  }"
              />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 comentarios">
              <label class="form-label ms-1">Comentarios</label>
              <textarea class="form-control shadow-none rounded-0 text-black" v-model="comentarios"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_Tres" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-if="ventana===2">
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 metodo_pago">
              <label class="form-label ms-1">M&eacute;todo de pago<span class="text-danger fw-bold"> *</span></label>
              <select v-model="metodo_pago" :class="error_metodo_pago?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black" required>
                <option disabled :value="null">Seleccione el m&eacute;todo de pago</option>
                <option value="Tarjeta">Tarjeta</option>
                <option value="Efectivo">Efectivo</option>
                <option value="Transferencia Bancaria">Transferencia Bancaria</option>
              </select>
              <div v-if="error_metodo_pago" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 text-start monto">
            <label class="form-label ms-1 d-block">Monto a pagar<span
                class="text-danger fw-bold">*</span></label>
            <input type="number" v-model="efectivo"
                   :min="0" class="form-control shadow-none rounded-0 text-black"
                   :class="error_efectivo?'is-invalid':''"
            />
            <div v-if="error_efectivo" class="invalid-feedback">
              Campo no puede estar vac&iacute;o
            </div>
          </div>
          <div class="row mb-3 tarjeta text-start" v-if="metodo_pago==='Tarjeta'">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 tarjeta_numero">
              <label class="form-label ms-1">N&uacute;mero de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="tarjeta_numero" :class="error_tarjeta_numero?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black" maxlength="16">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_vencimiento">
              <label class="form-label ms-1">Vencimiento de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_tarjeta" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             teleport-center :state="!error_tarjeta_vencimiento" month-picker
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
              <div v-if="error_tarjeta_vencimiento" class="invalid-feedback" style="display: block !important;">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
          </div>
          <div class="row mb-3 efectivo text-start" v-else-if="metodo_pago==='Transferencia Bancaria'">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 concepto">
              <label class="form-label ms-1">Concepto<span class="text-danger fw-bold"> *</span></label>
              <textarea class="form-control shadow-none rounded-0 text-black"
                        :class="error_transferencia_concepto?'is-invalid':'border-success'"
                        required
                        v-model="transferencia_concepto"></textarea>
              <div v-if="error_transferencia_concepto" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 referencia">
              <label class="form-label ms-1">Referencia<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_transferencia_referencia?'is-invalid':'border-success'"
                     v-model="transferencia_referencia"
                     required
                     class="form-control shadow-none rounded-0 text-black">
              <div v-if="error_transferencia_referencia" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 banco">
              <label class="form-label ms-1">Banco<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_transferencia_banco?'is-invalid':'border-success'" v-model="transferencia_banco"
                     class="form-control shadow-none rounded-0 text-black" required>
              <div v-if="error_transferencia_banco" class="invalid-feedback">
                Campo no puede estar vac&iacute;o
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa diaria</span>
              <span>{{ dias }} X $ {{ formatearNumero(tarifa_diaria) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Subtotal</span>
              <span>$ {{ formatearNumero(subtotal) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de servicios extras</span>
              <span>$ {{ formatearNumero(tarifa_servicios) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa iva ({{ formatearNumero(iva) }} %)</span>
              <span>$ {{ formatearNumero(tarifa_iva) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de protecci&oacute;n</span>
              <span>{{ dias }} X $ {{ formatearNumero(protection_price) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Total</span>
              <span>$ {{ formatearNumero(total) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de drop off</span>
              <span>$ {{ formatearNumero(tarifa_dropoff) }} MXN</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-4 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-4 d-flex justify-content-center mb-3">
              <button @click="efectivo=parseFloat(total).toFixed(2)" class="btn btn-success d-block w-100"
                      type="button">Pagar todo
              </button>
            </div>
            <div class="col-md-12 col-lg-4 d-flex justify-content-end mb-3">
              <button v-if="accion==='nuevo'" @click="Crear" class="btn btn-danger d-block w-100">
                Guardar
              </button>
              <button v-else @click="Editar" class="btn btn-danger d-block w-100">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold d-flex justify-content-between">
            <span>Detalles de la reserva</span>
            <i @click="close_Details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-show="pagina===0" class="row mb-1 text-start">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>ID:</h6>
            <p>{{ item.reservaNo }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Estado:</h6>
            <p>{{ item.estado }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Procedencia:</h6>
            <p>{{ item.procedencia.nombre }}</p>
          </div>
          <h5>Datos del cliente</h5>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Nombre:</h6>
            <p>{{ item.cliente.nombre }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Apellidos:</h6>
            <p>{{ item.cliente.apellidos }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Correo:</h6>
            <p>{{ item.cliente.user.email }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Tel&eacute;fono:</h6>
            <p>{{
                item ? item.cliente.contactoSet.edges.length > 0 ? item.cliente.contactoSet.edges[0].node.telefono : "" : ""
              }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Fecha de salida:</h6>
            <p>{{ formatearFecha(item.fechaSalida) }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Fecha de regreso:</h6>
            <p>{{ formatearFecha(item.fechaRegreso) }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Sucursal de recogida:</h6>
            <p>{{ item.reservaSucursalRecogida.name }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Sucursal de entrega:</h6>
            <p>{{ item.reservaSucursalEntrega.name }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Categor&iacute;a:</h6>
            <p>{{ item.categoria.name }}</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <h6>Comentario:</h6>
            <p>{{ item.comentario }}</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3" v-show="all_serivicios_extras.length>0">
            <h6>Servicios extras:</h6>
            <ul>
              <li v-for="item in all_serivicios_extras" :key="item.id">{{ item.servicio.name }}</li>
            </ul>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-1 text-start">
          <h5>Tarifas</h5>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>M&eacute;todo de pago:</h6>
            <p>{{ item.metodoPago }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Tarifa de protecci&oacute;n:</h6>
            <p>$ {{ formatearNumero(item.totalProteccion) }} MXN</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Subtotal</h6>
            <p>$ {{ formatearNumero(parseFloat(subtotal) - parseFloat(item.totalPromocion)) }} MXN</p>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Protecci&oacute;n:</h6>
            <p>{{ item.proteccion ? item.proteccion.name : "No tiene" }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Total de tarifa diaria:</h6>
            <p>$ {{ formatearNumero(parseFloat(item.priceExtra) * parseInt(item.dias)) }} MXN</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>IVA:</h6>
            <p>$ {{ formatearNumero(item.iva) }} MXN</p>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>D&iacute;as:</h6>
            <p>{{ item.dias }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Tarifa de servicios extras:</h6>
            <p>$ {{ formatearNumero(item.totalServicios) }} MXN</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Total de la reserva:</h6>
            <p>$ {{ formatearNumero(parseFloat(item.total) - parseFloat(item.totalPromocion)) }} MXN</p>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Tarifa diaria:</h6>
            <p>$ {{ formatearNumero(item.priceExtra) }} MXN</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Tarifa de Drop off:</h6>
            <p>$ {{ formatearNumero(item.totalDropoff) }} MXN</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6 class="text-success">Total de la reserva pagado:</h6>
            <p class="text-success">$ {{ formatearNumero(item.montoAPagar) }} MXN</p>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Promoci&oacute;n:</h6>
            <p>$ {{ formatearNumero(item.totalPromocion) }}
              MXN</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6 class="text-danger">Pago pendiente:</h6>
            <p class="text-danger">$ {{
                formatearNumero(parseFloat(item.total) - parseFloat(item.montoAPagar) - parseFloat(item.totalPromocion))
              }}
              MXN</p>
          </div>
        </div>
        <div v-show="pagina===0" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Details" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="pagina=1" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
          </div>
        </div>
        <div v-show="pagina===1" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="pagina=0" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="close_Details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Subir_foto text-dark">
    <div v-if="Photo_Modal" :class="['modal', { 'show': Photo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Subir o cambiar foto del cliente
            <i @click="close_Photo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Foto de cliente</label>
            <input :class="cliente_photo===null?'is-invalid':''" @change="onPhoto"
                   class="form-control shadow-none rounded-0 text-black" type="file">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <img class="img-fluid img-cliente" :src="preview" alt="Imagen del cliente">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Debe seleccionar una foto
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Photo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Subir_Photo" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ChangeCar text-dark">
    <div v-if="carModal" :class="['modal1', { 'show': carModal }]">
      <div class="modal-content1 bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Cambiar veh&iacute;culo de la reserva
            <i @click="closeCar" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Veh&iacute;culo</label>
            <select :class="car===null?'is-invalid':''"
                    class="form-control shadow-none rounded-0 text-black" v-model="car">
              <option v-show="carSelected.id===0" disabled :value="0">Seleccione un veh&iacute;culo</option>
              <option v-show="carSelected.id!==0" :value="carSelected.id">{{ carSelected.noEcon }}</option>
              <option v-for="item in cars" :key="item.id" :value="item.id">{{ item.noEconomico }}</option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Debe seleccionar un veh&iacute;culo
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="closeCar" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="saveCar" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="PDF">
    <div v-if="Print_Modal" :class="['modal-print', { 'show': Print_Modal }]">
      <div class="modal-content-print" style="background-color: #fff">
        <i @click="close_Print" class="flaticon-close opacity-10 modal-icon-print fs-15"></i>
        <div ref="reserva_pdf" class="mb-3 p-4">
          <div class="row mb-2">
            <div class="col-5 d-flex justify-content-start align-items-center">
              <img src="@/assets/images/logo3.png"
                   style="width: 120px; height: 100px">
            </div>
            <div class="col-7 text-start">
              <p style="color: #000">C. Josefina Ortiz de Contreras 33. Tijuana</p>
              <ul>
                <li style="color: #000"><strong>Tel&eacute;fono:</strong> (664) 851-7016</li>
                <li style="color: #000"><strong>Tel&eacute;fono:</strong> (664) 899-5338</li>
                <li style="color: #000"><strong>Correo:</strong> streetrentacar.com.mx@gmail.com</li>
                <li style="color: #000"><strong>Hora de oficina:</strong> 7AM- 7PM</li>
              </ul>
            </div>
          </div>
          <div class="row mb-2">
            <strong class="mb-2"  style="font-size: 16px; color:#000">Detalles de la Reserva desde {{ item.procedencia.nombre }}</strong>
            <div class="col-12 text-start" style="font-size: 12px; color:#000"><strong>No. Reserva:</strong> {{
                item.reservaNo
              }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Nombre:</strong> {{
                item.cliente.nombre
              }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Correo:</strong> {{
                item.cliente.user.email
              }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Apellidos:</strong> {{
                item.cliente.apellidos
              }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Tel&eacute;fono:</strong> {{
                item.cliente.contactoSet.edges[0].node.telefono
              }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Sucursal de
              recogida:</strong>
              {{ item.reservaSucursalRecogida.name }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Sucursal de entrega:</strong>
              {{ item.reservaSucursalEntrega.name }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Fecha de recogida:</strong>
              {{ formatearFecha(item.fechaSalida) }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Fecha de entrega:</strong>
              {{ formatearFecha(item.fechaRegreso) }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"><strong>Categor&iacute;a
              contratada:</strong> {{
                item.categoria.name
              }}
            </div>
            <div class="col-6 text-start" style="font-size: 12px; color:#000"
                 v-if="all_serivicios_extras.length>0"><strong>Servicios a contratar:</strong>
              <ul>
                <li v-for="i in all_serivicios_extras" :key="i.id">{{ i.servicio.name }}</li>
              </ul>
            </div>
            <div class="col-12 text-start" style="font-size: 12px; color:#000" v-if="item.proteccion">
              <strong>Protecci&oacute;n:</strong> {{ item.proteccion.name }} $
              {{ formatearNumero(item.proteccion.tarifa) }}
              MXN
              <p>El paquete de protecci&oacute;n incluye las siguientes protecciones</p>
              <ul>
                <li v-for="elements in all_protecciones" :key="elements.id" v-show="item.isActive">{{
                    elements.nombre
                  }}
                </li>
              </ul>
              <p>{{ item.proteccion.descripcion }}</p>
            </div>
          </div>
          <p style="color:#0c0c0c; font-weight: bold">Importe</p>
          <div class="row border border-success" style="border-radius: 15px">
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Días reservados:</p>
              <p> {{ item.dias }}</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Promocion:</p>
              <p>$ {{ formatearNumero(item.totalPromocion) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Tarifa de categoría:</p>
              <p>$ {{formatearNumero(this.item.priceExtra)}} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Subtotal:</p>
              <p>$ {{ formatearNumero(subtotal) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Protección:</p>
              <p>$ {{ formatearNumero(item.totalProteccion) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">IVA:</p>
              <p>$ {{ formatearNumero(item.iva) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Servicios extras:</p>
              <p>$ {{ formatearNumero(item.totalServicios) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Importe total:</p>
              <p>$ {{ formatearNumero(item.total) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Dropoff:</p>
              <p>$ {{ formatearNumero(item.totalDropoff) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: green"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold text-success">Importe pagado:</p>
              <p class="text-success">$ {{ formatearNumero(item.montoAPagar) }} MXN</p>
            </div>
            <div style="font-size: 12px; color: #000"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold">Puntos:</p>
              <p> {{ item.puntos }}</p>
            </div>
            <div style="font-size: 12px; color: darkred"
                 class="col-6 mb-0 d-flex justify-content-between align-items-center">
              <p class="fw-bold text-danger">Pago pendiente:</p>
              <p class="text-danger">$ {{
                  formatearNumero(parseFloat(item.total) - parseFloat(item.montoAPagar) - parseFloat(item.totalPromocion))
                }}
                MXN</p>
            </div>
          </div>
          <div class="row text-dark">
            <p class="mt-2" style="color:#0c0c0c; font-weight: bold">Para conocer los términos y condiciones visite
              nuestro portal web <a
                  href="https://streetrentacar.com.mx/pages/terminos.html">https://streetrentacar.com.mx/pages/terminos.html</a>
            </p>
          </div>
          <div class="row" style="color:#0d0c1d; margin-top: 50px">

           <div class="col-6 text-center">{{item.createdBy?item.createdBy.firstName : ""}} {{ item.createdBy ? item.createdBy.lastName : ""}}<br>Agente
           </div>
            <div class="col-6 text-center">{{item.cliente.nombre}} {{item.cliente.apellidos}}<br>Cliente</div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button class="btn btn-secondary" @click="close_Print">Cancelar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button class="btn btn-dark" @click="generateReportPDF">Descargar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top:99999px" v-show="export_excel">
    <table id="table_to_export" class="table text-nowrap align-middle mb-0">
      <thead>
      <tr>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Estado
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          N&uacute;mero de reserva
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Cliente
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Categor&iacute;a
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Procedencia
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Fecha de salida
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Fecha de regreso
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Creado por
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Total
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Pendiente
        </th>
        <th
            scope="col"
            class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
        >
          Pagado
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.estado }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.reservaNo }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.cliente ? item.cliente.user.email : "" }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.categoria.name }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.procedencia.nombre }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ formatearFecha(item.fechaSalida) }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ formatearFecha(item.fechaRegreso) }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          {{ item.createdBy ? item.createdBy.email : "" }}
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          $ {{
            formatearNumero(parseFloat(item.total) - parseFloat(item.totalPromocion))
          }} MXN
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
          $ {{ getMontoPendiente(item.total, item.montoAPagar, item.totalPromocion) }} MXN
        </td>
        <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <span class="total">$ {{
                  formatearNumero(item.montoAPagar)
                }} MXN</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Swal from "sweetalert2";
import moment from "moment";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import MazInputPrice from 'maz-ui/components/MazInputPrice';
import MazInputNumber from 'maz-ui/components/MazInputNumber';
import html2pdf from "html2pdf.js";
import MazSwitch from 'maz-ui/components/MazSwitch'
import * as XLSX from "xlsx";
import {
  createReserve,
  reserveById,
  searchNextReserves,
  searchPrevReserves,
  searchReserves,
  updateReserve,
  isHightSeason,
  getIVA,
  getClientForReserve,
  searchReservesDate,
  cancelReserva,
  guardarReservaPdf,
  getBranchOfficeReserve,
  getCategoriesReserve,
  getProtectionsReserve,
  getServicesReserve,
  getOriginReserve,
  getServicesbyReserve,
  getDisponibilidad, getAllAutoForContract, changeCarReserved
} from "@/actions";
import {decodeBase64} from "@/util";
import {srac_url_image} from "@/actions/constants";

const PROTECCIONES = gql`
query ProteccionesApp {
    proteccionesApp {
        id
        name
        descripcion
        tarifa
        isActive
    }
}`;
const TARIFA_DROPOFF = gql`
query TarifaDropoff($sucursal1:ID!
$sucursal2:ID!) {
    tarifaDropoff(sucursal1: $sucursal1, sucursal2: $sucursal2)
}`;
const TARIFA_SERVICIOS = gql`
query TarifaServicios($servicios:[ID!], $dias:Int!) {
    tarifaServicios(servicios: $servicios, dias: $dias)
}`;
const SERVICIOS_RESERVA = gql`
query ServiciosByReserva($id:ID!) {
    serviciosByReserva(id: $id) {
        id
        servicio {
            id
            isActive
            diario
            name
            precio
        }
    }
}`;
const PROTECCION_ID = gql`
query ProteccionById($id:ID!) {
    proteccionById(id: $id) {
        id
        name
        descripcion
        tarifa
        isActive
    }
}`;
const PROTECCIONESBYID = gql`
query ProteccionesById($id:ID!) {
    proteccionesById(id: $id) {
        id
        nombre
        isActive
    }
}`;
export default {
  name: "ReservaList",
  components: {
    MazPhoneNumberInput,
    MazInputPrice,
    MazInputNumber,
    MazSwitch
  },
  data() {
    return {
      carSelected: {
        id: 0,
        noEcon: ""
      },
      reserveId: 0,
      cars: [],
      car: null,
      carModal: false,
      export_excel: false,
      category_update: "",
      subtotal: 0,
      search_date: null,
      category_name: "",
      error_diario: false,
      porcent_iva: 0,
      rfc: "",
      protection_price: 0,
      init: false,
      iva: 0,
      total_diario: 0,
      sucursalesApp: [],
      estado: 0,
      Estado_Modal: false,
      is_higth_season: false,
      categoriasByName: [],
      proteccionesApp: [],
      serviciosExtrasApp: [],
      procedenciaByName: [],
      itemsPerPage: 10,
      currentPage: 1,
      search: "",
      Nuevo_Modal: false,
      accion: "nuevo",
      ventana: 0,
      error_vacios: false,
      currentStep: 1,
      steps: [1, 2, 3],
      Details_Modal: false,
      Print_Modal: false,
      pagina: 0,
      item: null,

      nombre: "",
      apellidos: "",
      email: "",
      telefono: "",
      sucursal_recogida: 0,
      sucursal_entrega: 0,
      fecha_salida: null,
      fecha_regreso: null,
      categoria: null,
      proteccion: 0,
      servicios: 0,
      tarjeta_numero: "",
      tarjeta_autorizacion: "",
      tarjeta_vencimiento: null,
      metodo_pago: null,
      fecha_tarjeta: null,
      efectivo: 0,
      codigo: '',
      comentarios: "",
      procedencia: 0,

      error_nombre: false,
      error_apellidos: false,
      error_email: false,
      error_telefono: false,
      error_sucursal_recogida: false,
      error_sucursal_entrega: false,
      error_categoria: false,
      error_proteccion: false,
      error_servicios: false,
      error_tarjeta_numero: false,
      error_tarjeta_autorizacion: false,
      error_expiracion: false,
      error_metodo_pago: false,
      error_fecha_salida: false,
      error_fecha_regreso: false,
      error_tarjeta_vencimiento: false,
      error_efectivo: false,
      tipo_pago: 'tarjeta',
      error_procedencia: false,
      error_transferencia_concepto: false,
      error_transferencia_referencia: false,
      error_transferencia_banco: false,

      servicios_extras: [],
      categoria_aux: {},

      total: 0,
      tarifa_dropoff: 0,
      tarifa_servicios: 0,
      tarifa_proteccion: 0,
      tarifa_iva: 0,
      tarifa_diaria: 0,
      dias: 0,
      price_extra: 0,
      cargando: false,
      transferencia_concepto: "",
      transferencia_referencia: "",
      transferencia_banco: "",
      Update_Modal: false,
      showSubmenu: false,
      deshabilitado: false,
      autocompleteItems: [],
      clientesByName: [],
      all_serivicios_extras: [],
      all_protecciones: [],
      id: 0,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: [],
      loading: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  watch: {
    search_date() {
      this.loadDatas(true)
    },
    nombre(value) {
      if (value !== "") {
        this.error_nombre = false
      }
    },
    apellidos(value) {
      if (value !== "") {
        this.error_apellidos = false
      }
    },
    email(value) {
      if (value !== "" && /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
        this.error_email = false
      }
    },
    telefono(value) {
      if (value !== "") {
        this.error_telefono = false
      }
    },
    fecha_salida(value) {
      if (value !== null) {
        isHightSeason(value).then(response => {
          this.is_higth_season = response.data.data.isHighSeason
        })
      }
      if (this.accion === "nuevo") {
        if (value !== null && this.Diferencia_Fechas(Date.now(), value) >= 1 && this.Diferencia_Fechas(value, this.fecha_regreso) >= 1) {
          this.error_fecha_salida = false
          this.error_fecha_regreso = false
          this.dias = parseInt(this.Diferencia_Fechas(value, this.fecha_regreso))
          this.Calcular_Servicios()
          this.Calcular_Proteccion()
        }
      } else {
        if (value !== null && this.Diferencia_Fechas(value, this.fecha_regreso) >= 1) {
          this.error_fecha_salida = false
          this.error_fecha_regreso = false
          this.dias = parseInt(this.Diferencia_Fechas(value, this.fecha_regreso))
          this.Calcular_Servicios()
          this.Calcular_Proteccion()
        }
      }
      this.search_Disponibilidad()
    },
    fecha_regreso(value) {
      if (value !== null && this.Diferencia_Fechas(this.fecha_salida, value) >= 1) {
        this.error_fecha_salida = false
        this.error_fecha_regreso = false
        this.dias = parseInt(this.Diferencia_Fechas(this.fecha_salida, value))
        this.Calcular_Servicios()
        this.Calcular_Proteccion()
      }
      this.search_Disponibilidad()
    },
    servicios(value) {
      if (value !== 0) {
        this.servicios_extras.push(parseInt(value))
        this.Calcular_Servicios()
      }
    },
    metodo_pago(value) {
      if (value !== null) {
        this.error_metodo_pago = false;
        if (value.toLowerCase().includes('tarjeta')) {
          this.tipo_pago = "tarjeta"
        }
        if (value.toLowerCase().includes('efectivo')) {
          this.tipo_pago = "efectivo"
        }
        if (value.toLowerCase().includes('transferencia')) {
          this.tipo_pago = "transferencia"
        }
      }
    },
    sucursal_recogida(value) {
      if (value !== 0) {
        this.error_sucursal_recogida = false;
        getIVA({
          id: value
        }).then(response => {
          this.iva = response.data.data.ivaBySucursal;
        }).catch(err => console.log(err));
        this.Calcular_Dropoff();
      }
      this.search_Disponibilidad()
    },
    sucursal_entrega(value) {
      if (value !== 0) {
        this.error_sucursal_entrega = false;
        this.Calcular_Dropoff();
      }
    },
    categoria(value) {
      if (value) {
        this.error_categoria = false;
        this.category_name = value.name
        if (this.accion === "nuevo") {
          this.tarifa_diaria = value.precio;
        } else {
          if (this.init) {
            this.tarifa_diaria = value.precio;
          }
          this.init = true
        }
        this.price_extra = value.precio;
        this.Calcular_Total()
      }
    },
    proteccion(value) {
      if (value !== 0) {
        this.error_proteccion = false;
        this.Calcular_Proteccion()
      } else {
        this.tarifa_proteccion = 0;
        this.Calcular_Total();
        this.error_proteccion = true;
      }
    },
    efectivo(value) {
      if (value > 0) {
        this.error_efectivo = false
      }
    },
    procedencia(value) {
      if (value !== 0) {
        this.error_procedencia = false
      }
    },
    tarjeta_autorizacion(value) {
      if (value !== "" && value.toString().length === 4) {
        this.error_tarjeta_autorizacion = false
      }
    },
    tarjeta_numero(value) {
      if (value !== "" && value.toString().length === 16) {
        this.error_tarjeta_numero = false
      }
    },
    fecha_tarjeta(value) {
      if (value !== null && value !== "" && this.Diferencia_Fechas(Date.now(), this.fecha_tarjeta) >= 1) {
        this.error_tarjeta_vencimiento = false
      }
    },
    tarifa_diaria(value) {
      if (value >= this.price_extra) {
        this.error_diario = false
      } else {
        this.error_diario = true
      }
    },
    item() {
      if (this.accion === "details") {
        this.Details_Modal = true
        this.cargando = false
      }
      if (this.accion === "print") {
        this.Print_Modal = true
        this.cargando = false
      }
    },
    transferencia_concepto(value) {
      if (value !== "") {
        this.error_transferencia_concepto = false
      }
    },
    transferencia_referencia(value) {
      if (value !== "") {
        this.error_transferencia_referencia = false
      }
    },
    transferencia_banco(value) {
      if (value !== "") {
        this.error_transferencia_banco = false
      }
    },

    items() {
      this.cargando = false;
    },
    cant() {
      this.loadDatas(true);
    },
    search() {
      this.loadDatas(true);
    }
  },
  methods: {
    getCars() {
      if (this.fecha_salida !== null && this.fecha_regreso !== null && this.categoria !== null && this.sucursal_recogida !== null) {
        getAllAutoForContract({
          category: decodeBase64(this.categoria),
          fechaSalida: this.fecha_salida,
          fechaRegreso: this.fecha_regreso,
          ciudad: decodeBase64(this.sucursal_recogida)
        }).then(resp => {
          this.cars = resp.data.data.vehiculosDisponibles
        }).catch(err => console.log(err));
      }
    },
    openCar(item) {
      this.car = item.vehiculoReservadoSet.length > 0 ? item.vehiculoReservadoSet[0].vehiculo.id : 0
      this.carSelected.noEcon = item.vehiculoReservadoSet.length > 0 ? item.vehiculoReservadoSet[0].vehiculo.noEconomico : ""
      this.carSelected.id = item.vehiculoReservadoSet.length > 0 ? item.vehiculoReservadoSet[0].vehiculo.id : 0
      this.reserveId = decodeBase64(item.id)
      getAllAutoForContract({
        category: decodeBase64(item.categoria.id),
        fechaSalida: item.fechaSalida,
        fechaRegreso: item.fechaRegreso,
        ciudad: decodeBase64(item.reservaSucursalRecogida.id)
      }).then(resp => {
        this.cars = resp.data.data.vehiculosDisponibles
        this.carModal = true
      }).catch(err => console.log(err));
    },
    closeCar() {
      this.car = null;
      this.carSelected.id = 0;
      this.carSelected.noEcon = "";
      this.error_vacios = false
      this.carModal = false;

    },
    saveCar() {
      this.cargando = true
      if (this.reserveId !== 0 && this.car !== 0) {
        changeCarReserved({
          carId: decodeBase64(this.car),
          reserveId: this.reserveId
        }).then(resp => {
          if (resp.data.data.changeCarReserved.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.loadDatas()
            this.closeCar()
          } else {
            this.cargando = false
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "Error al modificar el vehículo"
            });
          }
        })
      } else {
        this.cargando = false
        this.error_vacios = true
      }
    },
    Calcular_Servicios() {
      if (this.servicios_extras.length > 0 && this.dias > 0) {
        this.$apollo.query({
          query: TARIFA_SERVICIOS,
          variables: {
            servicios: this.servicios_extras,
            dias: this.dias
          }
        }).then(response => {
          this.tarifa_servicios = response.data.tarifaServicios
          this.Calcular_Total()
          this.servicios = 0
        })
      } else {
        this.tarifa_servicios = 0
        this.servicios = 0
        this.Calcular_Total()
      }
    },
    Calcular_Proteccion() {
      if (this.proteccion !== 0 && this.dias > 0) {
        this.$apollo.query({
          query: PROTECCION_ID,
          variables: {
            id: this.proteccion
          }
        }).then(response => {
          this.protection_price = response.data.proteccionById.tarifa
          this.tarifa_proteccion = parseFloat(response.data.proteccionById.tarifa) * parseInt(this.dias)
          this.Calcular_Total()
        })
      }
    },
    search_Disponibilidad() {
      if (this.fecha_salida !== null && this.fecha_regreso !== null && this.sucursal_recogida !== 0) {
        getDisponibilidad({
          fechaSalida: this.fecha_salida,
          fechaRegreso: this.fecha_regreso,
          ciudad: this.sucursal_recogida
        }).then(response => {
          this.categoriasByName = response.data.data.categoriasDisponiblesAdmin
        })
      }
    },
    openNuevo() {
      this.ventana = 0;
      this.currentStep = 1;
      this.Nuevo_Modal = true;
      this.accion = "nuevo"
      getBranchOfficeReserve().then(response => {
        this.sucursalesApp = response.data.data.sucursalesApp
      })
      getCategoriesReserve().then(response => {
        this.categoriasByName = response.data.data.categoriasByName
      })
      getProtectionsReserve().then(response => {
        this.proteccionesApp = response.data.data.proteccionesApp
      })
      getServicesReserve().then(response => {
        this.serviciosExtrasApp = response.data.data.serviciosExtrasApp
      })
      getOriginReserve().then(response => {
        this.procedenciaByName = response.data.data.procedenciaByName
        this.procedenciaByName.forEach(item => {
          if (item.nombre.includes("Directas")) {
            this.procedencia = item.id
          }
        })
      })
    },
    openUpdate(item) {
      this.accion = "update"
      this.ventana = 0;
      this.currentStep = 1;
      this.id = decodeBase64(item.id)
      this.Nuevo_Modal = true;
      getBranchOfficeReserve().then(response => {
        this.sucursalesApp = response.data.data.sucursalesApp
      })
      getCategoriesReserve().then(response => {
        this.categoriasByName = response.data.data.categoriasByName
      })
      getProtectionsReserve().then(response => {
        this.proteccionesApp = response.data.data.proteccionesApp
      })
      getServicesReserve().then(response => {
        this.serviciosExtrasApp = response.data.data.serviciosExtrasApp
      })
      getOriginReserve().then(response => {
        this.procedenciaByName = response.data.data.procedenciaByName
      })
      this.cargando = true
      getServicesbyReserve({
        id: decodeBase64(item.id)
      }).then(response => {
        const services = response.data.data.serviciosByReserva
        services.forEach(i => {
          this.servicios_extras.push(decodeBase64(i.servicio.id))
        })
      }).catch(err => console.log(err));
      reserveById({
        id: decodeBase64(item.id)
      }).then(response => {
        const reservation = response.data.data.reservaById;
        this.nombre = reservation.cliente.nombre;
        this.apellidos = reservation.cliente.apellidos;
        this.email = reservation.cliente.user.email;
        this.rfc = reservation.cliente.rfc;
        this.telefono = reservation.cliente.contactoSet.edges[0].node.telefono;
        this.codigo = reservation.cliente.contactoSet.edges[0].node.codigo;
        this.sucursal_recogida = decodeBase64(reservation.reservaSucursalRecogida.id).toString();
        this.sucursal_entrega = decodeBase64(reservation.reservaSucursalEntrega.id).toString();
        this.fecha_salida = reservation.fechaSalida;
        isHightSeason(this.fecha_salida).then(response => {
          this.is_higth_season = response.data.data.isHighSeason
        })
        this.fecha_regreso = reservation.fechaRegreso;
        this.categoria = reservation.categoria;
        this.category_update = reservation.categoria.name;
        this.proteccion = reservation.proteccion !== null ? reservation.proteccion.id : 0;
        this.comentarios = reservation.comentario;
        this.procedencia = reservation.procedencia.id;
        this.metodo_pago = reservation.metodoPago;
        this.transferencia_referencia = reservation.transferReference?reservation.transferReference:""
        this.transferencia_banco = reservation.transferBank?reservation.transferBank:""
        this.tarjeta_numero = reservation.tarjetNumber?reservation.tarjetNumber:""
        this.fecha_tarjeta = reservation.tarjetExp?reservation.tarjetExp:""
        this.transferencia_concepto = reservation.transferConcept?reservation.transferConcept:""
        this.efectivo = reservation.montoAPagar;
        this.tarifa_servicios = parseFloat(reservation.totalServicios)
        this.tarifa_dropoff = parseFloat(reservation.totalDropoff)
        this.tarifa_proteccion = parseFloat(reservation.totalProteccion)
        this.tarifa_diaria = reservation.priceExtra;
      }).catch(err => console.log(err)).finally(() => {
        this.cargando = false;
      });
    },
    Details(id) {
      this.accion = "details";
      this.cargando = true;
      reserveById({
        id: decodeBase64(id)
      }).then(response => {
        this.item = response.data.data.reservaById;
        this.subtotal = (parseInt(this.item.dias) * parseFloat(this.item.priceExtra)) + parseFloat(this.item.totalServicios) + parseFloat(this.item.totalProteccion) + parseFloat(this.item.totalDropoff)
        this.$apollo.query({
          query: SERVICIOS_RESERVA,
          variables: {
            id: decodeBase64(this.item.id)
          },
          fetchPolicy: "network-only"
        }).then(response => {
          this.all_serivicios_extras = response.data.serviciosByReserva
        })
        this.Details_Modal = true
        this.cargando = false
      })
    },
    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    Close_nuevo() {
      this.loadDatas(true)
      this.nombre = ""
      this.init = false
      this.apellidos = ""
      this.rfc = "";
      this.email = ""
      this.category_update = ""
      this.telefono = ""
      this.sucursal_recogida = 0
      this.sucursal_entrega = 0
      this.fecha_salida = null
      this.fecha_regreso = null
      this.categoria = 0
      this.proteccion = 0
      this.servicios = 0
      this.tarjeta_numero = ""
      this.tarjeta_autorizacion = ""
      this.tarjeta_vencimiento = null
      this.metodo_pago = null
      this.fecha_tarjeta = null
      this.efectivo = 0
      this.ventana = 0;
      this.currentStep = 1;
      this.Nuevo_Modal = false;
      this.procedencia = 0
      this.price_extra = 0;

      this.error_nombre = false;
      this.error_apellidos = false;
      this.error_email = false;
      this.error_telefono = false;
      this.error_sucursal_recogida = false;
      this.error_sucursal_entrega = false;
      this.error_categoria = false;
      this.error_proteccion = false;
      this.error_servicios = false;
      this.error_tarjeta_numero = false;
      this.error_tarjeta_autorizacion = false;
      this.error_tarjeta_vencimiento = false;
      this.error_expiracion = false;
      this.error_metodo_pago = false;
      this.error_fecha_salida = false;
      this.error_fecha_regreso = false;
      this.error_efectivo = false;
      this.error_procedencia = false;
      this.error_vacios = false;

      this.total = 0
      this.tarifa_dropoff = 0
      this.tarifa_servicios = 0
      this.tarifa_proteccion = 0
      this.tarifa_iva = 100.00
      this.tarifa_diaria = 0
      this.dias = 0
      this.servicios_extras = []
      this.cargando = false
    },
    close_Details() {
      this.pagina = 0
      this.subtotal = 0
      this.all_serivicios_extras = []
      this.tarifa_diaria = 0
      this.Details_Modal = false
      this.accion = ""
    },
    close_Print() {
      this.cargando = false
      this.Print_Modal = false
      this.id = 0
      this.accion = ""
      this.all_serivicios_extras = []
    },
    Siguiente_Dos() {
      var validacion = true
      if (this.procedencia === 0) {
        validacion = false;
        this.error_procedencia = true;
      }
      if (this.sucursal_recogida === 0) {
        validacion = false
        this.error_sucursal_recogida = true
      }
      if (this.sucursal_entrega === 0) {
        validacion = false
        this.error_sucursal_entrega = true
      }
      if (this.tarifa_diaria === 0) {
        this.error_diario = true
        validacion = false
      }
      if (this.categoria === null) {
        validacion = false
        this.error_categoria = true
      }
      if (this.accion === "nuevo") {
        if (this.fecha_salida === "" || this.fecha_salida === null) {
          this.error_fecha_salida = true
          validacion = false
        }
      } else {
        if (this.fecha_salida === "" || this.fecha_salida === null) {
          this.error_fecha_salida = true
          validacion = false
        }
      }

      if (this.fecha_regreso === "" || this.fecha_regreso === null || this.Diferencia_Fechas(this.fecha_salida, this.fecha_regreso) < 1) {
        this.error_fecha_regreso = true
        validacion = false
      }
      if (validacion) {
        this.ventana = 1
        this.currentStep = 2
        this.error_vacios = false
      } else {
        this.error_vacios = true
      }
    },
    Regresar() {
      this.ventana = this.ventana - 1
      this.currentStep = this.currentStep - 1
    },
    Siguiente_Tres() {
      let validacion = true
      if (this.nombre === "") {
        this.error_nombre = true
        validacion = false
      }
      if (this.apellidos === "") {
        this.error_apellidos = true
        validacion = false
      }
      if (this.telefono === "") {
        this.error_telefono = true
        validacion = false
      }
      if (this.email === "" || /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
        this.error_email = true
        validacion = false
      }
      if (validacion) {
        this.Calcular_Total()
        this.error_vacios = false
        this.ventana = 2
        this.currentStep = 3
      } else {
        this.error_vacios = true
        return false
      }
    },
    Crear() {
      this.cargando = true
      let validacion = true
      if (this.efectivo < 0) {
        validacion = false
        this.error_efectivo = true;
      }
      if (this.metodo_pago === null) {
        validacion = false
        this.error_metodo_pago = true
      }
      if (this.tipo_pago === "tarjeta") {
        if (this.tarjeta_numero === "" || this.tarjeta_numero.toString().length < 14) {
          validacion = false
          this.error_tarjeta_numero = true
        }
        if (this.fecha_tarjeta === null || this.fecha_tarjeta === "" || this.Diferencia_Fechas(Date.now(), this.fecha_tarjeta) < 1) {
          validacion = false
          this.error_tarjeta_vencimiento = true
        }
      }
      if (this.tipo_pago === "transferencia") {
        if (this.transferencia_referencia === "") {
          validacion = false
          this.error_transferencia_referencia = true
        }
        if (this.transferencia_banco === "") {
          validacion = false
          this.error_transferencia_banco = true
        }
        if (this.transferencia_concepto === "") {
          validacion = false
          this.error_transferencia_concepto = true
        }
      }
      if (validacion) {
        if (this.accion === 'nuevo') {
          createReserve({
            nombre: this.nombre,
            apellidos: this.apellidos,
            telefono: this.telefono,
            codigoTelefono: this.codigo,
            correo: this.email,
            comentario: this.comentarios,
            sucursalRecogida: this.sucursal_recogida,
            sucursalEntrega: this.sucursal_entrega,
            fechaSalida: this.fecha_salida,
            fechaRegreso: this.fecha_regreso,
            categoria: this.categoria.id,
            proteccion: this.proteccion !== 0 ? this.proteccion : 0,
            metodoPago: this.metodo_pago,
            procedencia: this.procedencia,
            total: this.total,
            servicios: this.servicios_extras,
            dias: this.dias,
            iva: this.tarifa_iva,
            priceExtra: this.tarifa_diaria,
            isPriceExtra: this.tarifa_diaria === this.price_extra,
            montoAPagar: this.efectivo,
            creator: this.$store.state.userid,
            transferConcept: this.transferencia_concepto,
            transferReference: this.transferencia_referencia,
            transferBank: this.transferencia_banco,
            tarjetNumber: this.metodo_pago === "Tarjeta" ? this.tarjeta_numero : "",
            tarjetExp: this.metodo_pago === "Tarjeta" ? this.dateVenc : null
          }).then(response => {
            if (response.data.data.createReservaAdmin.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.Close_nuevo()
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: response.data.data.createReservaAdmin.error
              });
              this.Close_nuevo()
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
            this.cargando = false
          })
        }

      } else {
        this.error_vacios = true
        this.cargando = false
      }
    },
    Editar() {
      this.cargando = true
      let validacion = true
      if (this.metodo_pago === null) {
        validacion = false
        this.error_metodo_pago = true
      }
      if (this.tipo_pago === "tarjeta") {
        if (this.tarjeta_numero === "" || this.tarjeta_numero.toString().length < 14) {
          validacion = false
          this.error_tarjeta_numero = true
        }
        if (this.fecha_tarjeta === null || this.fecha_tarjeta === "") {
          validacion = false
          this.error_tarjeta_vencimiento = true
        }
      }
      if (this.tipo_pago === "transferencia") {
        if (this.transferencia_referencia === "") {
          validacion = false
          this.error_transferencia_referencia = true
        }
        if (this.transferencia_banco === "") {
          validacion = false
          this.error_transferencia_banco = true
        }
        if (this.transferencia_concepto === "") {
          validacion = false
          this.error_transferencia_concepto = true
        }
      }
      if (validacion) {
        updateReserve({
          id: this.id,
          nombre: this.nombre,
          apellidos: this.apellidos,
          telefono: this.telefono,
          codigoTelefono: this.codigo,
          correo: this.email,
          comentario: this.comentarios,
          sucursalRecogida: this.sucursal_recogida,
          sucursalEntrega: this.sucursal_entrega,
          fechaSalida: this.fecha_salida,
          fechaRegreso: this.fecha_regreso,
          categoria: decodeBase64(this.categoria.id),
          proteccion: this.proteccion !== 0 ? this.proteccion : 0,
          metodoPago: this.metodo_pago,
          procedencia: parseInt(this.procedencia),
          total: this.total,
          servicios: this.servicios_extras,
          dias: this.dias,
          iva: this.tarifa_iva,
          priceExtra: this.tarifa_diaria,
          isPriceExtra: this.tarifa_diaria === this.price_extra,
          montoAPagar: this.efectivo,
          creator: this.$store.state.userid,
          transferConcept: this.transferencia_concepto,
          transferReference: this.transferencia_referencia,
          transferBank: this.transferencia_banco,
          tarjetNumber: this.tarjeta_numero,
          tarjetExp: this.dateVenc,
        }).then(response => {
          if (response.data.data.updateReservaAdmin.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo()
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.updateReservaAdmin.error
            });
            this.Close_nuevo()
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
          this.cargando = false
        })
      } else {
        this.error_vacios = true
        this.cargando = false
      }
    },
    Quitar_extra(id) {
      this.servicios_extras = this.servicios_extras.filter(item => item !== parseInt(id));
      this.Calcular_Servicios()
    },
    Diferencia_Fechas(fecha1, fecha2) {
      const diffInHours = moment(fecha2).diff(fecha1, 'hours');
      return Math.ceil(diffInHours / 24);
    },
    Calcular_Dropoff() {
      if (this.sucursal_recogida !== 0 && this.sucursal_entrega !== 0) {
        this.$apollo.mutate({
          mutation: TARIFA_DROPOFF,
          variables: {
            sucursal1: decodeBase64(this.sucursal_recogida),
            sucursal2: decodeBase64(this.sucursal_entrega)
          }
        }).then(response => {
          this.tarifa_dropoff = response.data.tarifaDropoff
          this.Calcular_Total()
        })
      }
    },
    Calcular_Total() {
      if (this.dias > 0) {
        this.total = parseFloat(this.tarifa_dropoff) + parseFloat(this.tarifa_servicios) + parseFloat(this.tarifa_proteccion) + parseFloat(parseFloat(this.tarifa_diaria) * parseInt(this.dias))
        this.subtotal = parseFloat(this.tarifa_dropoff) + parseFloat(this.tarifa_servicios) + parseFloat(this.tarifa_proteccion) + parseFloat(parseFloat(this.tarifa_diaria) * parseInt(this.dias))
        this.tarifa_iva = this.total * parseFloat(this.iva) / 100
        this.total = this.total + this.tarifa_iva
      }
    },
    formatearNumero(numero) {
      const num = parseFloat(numero).toFixed(2);
      return parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2});
    },
    autocomplete() {
      this.autocompleteItems = this.clientesByName.filter(item => item.user && item.user.email.toLowerCase().includes(this.email.toLowerCase()));
      this.showSubmenu = this.email !== "";
    },
    selectItem(item) {
      this.deshabilitado = true;
      this.nombre = item.nombre !== "" ? item.nombre : "";
      this.apellidos = item.apellidos !== "" ? item.apellidos : "";
      this.email = item.user.email;
      this.rfc = item.rfc;
      this.tarjeta_numero = item.tarjetaSet.edges.length > 0 ? item.tarjetaSet.edges[0].node.numero : "";
      this.fecha_tarjeta = item.tarjetaSet.edges.length > 0 ? new Date(item.tarjetaSet.edges[0].node.vencimiento) : "";
      this.telefono = item.contactoSet.edges.length !== 0 ? item.contactoSet.edges[0].node.telefono : "";
      this.codigo = item.contactoSet.edges.length !== 0 ? item.contactoSet.edges[0].node.codigo : "";
      this.showSubmenu = false;
    },
    createContrato(item) {
      this.$store.state.reserva = item
      this.$router.push({
        name: 'Contratos'
      });
    },
    async generateReportPDF(id) {
      this.id = id
      this.accion = "print"
      this.cargando = true
      reserveById({
        id: decodeBase64(id)
      }).then(response => {
        this.item = response.data.data.reservaById;
        this.subtotal = (parseInt(this.item.dias) * parseFloat(this.item.priceExtra)) + parseFloat(this.item.totalServicios) + parseFloat(this.item.totalProteccion) + parseFloat(this.item.totalDropoff)
        this.total_diario = parseInt(this.item.dias) * parseFloat(this.item.priceExtra)
        this.$apollo.query({
          query: SERVICIOS_RESERVA,
          variables: {
            id: decodeBase64(id)
          }
        }).then(response => {
          this.all_serivicios_extras = response.data.serviciosByReserva
        })
        if (this.item.proteccion) {
          this.$apollo.query({
            query: PROTECCIONESBYID,
            variables: {
              id: this.item.proteccion.id
            }
          }).then(response => {
            this.all_protecciones = response.data.proteccionesById
          })
        }
        setTimeout(() => {
          let base64Data = ""
          this.Print_Modal = true
          const contentToExport = this.$refs.reserva_pdf;
          const options = {
            margin: [17, 5, 20, 5],
            filename: "Reserva " + this.item.reservaNo + ".pdf",
            image: {type: "jpeg", quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: "mm", format: "a4", orientation: "portrait"}
          };
          html2pdf()
              .from(contentToExport)
              .set(options)
              .output("blob", "reserva.pdf")
              .then(dataUri => {
                const blob = new Blob([dataUri], {type: "application/pdf"});
                const url = URL.createObjectURL(blob);
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  base64Data = reader.result
                  guardarReservaPdf({
                    reserva: this.item.id,
                    pdf: base64Data
                  })
                }
                window.open(url, "_blank");
              });
          this.close_Print();
        }, 50);
      })
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm");
    },
    getMontoPendiente(total, pagado, promocion) {
      if (parseFloat(total) - parseFloat(promocion) - parseFloat(pagado) < 0) {
        return "0.00"
      } else {
        const pendiente_calc = parseFloat(total) - parseFloat(promocion) - parseFloat(pagado)
        const num = parseFloat(pendiente_calc).toFixed(2);
        return parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2});
      }
    },
    cancelarReserva(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, cancelar la reserva!"
      }).then(async (result) => {
        if (result.isDenied) {
          cancelReserva(decodeBase64(id)).then(resp => {
            if (resp.data.data.cancelReserva.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Reserva cancelada"
              });
              this.loadDatas();
            }
          }).catch(err => console.log(err));
        }
      });
    },
    exportToExcel() {
      this.export_excel = true
      const table = document.getElementById('table_to_export');
      if (table) {
        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Listado de reservas.xlsx");
        this.export_excel = false
      } else {
        console.error("No se encontró la tabla");
      }
    },

    loadDatas(init = false) {
      this.cargando = true
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      if (this.search_date === null) {
        searchReserves({
          name: this.search,
          before: before,
          after: next,
          first: this.cant
        }).then(resp => {
          if (init === true) {
            this.hasNextPage = resp.data.data.searchReserves.pageInfo.hasNextPage;
            this.hasPreviousPage = resp.data.data.searchReserves.pageInfo.hasPreviousPage;
          }
          this.startCursor = resp.data.data.searchReserves.pageInfo.startCursor;
          this.endCursor = resp.data.data.searchReserves.pageInfo.endCursor;
          this.items = resp.data.data.searchReserves.edges.map(value => {
            return value.node;
          });
        }).catch(err => {
          this.toast.fire({
            icon: "error",
            title: err.message
          });
        }).finally(() => {
          this.loading = false;
        });
      } else {
        searchReservesDate({
          name: this.search,
          before: before,
          after: next,
          first: this.cant,
          date: this.search_date
        }).then(resp => {
          if (init === true) {
            this.hasNextPage = resp.data.data.searchReservesDate.pageInfo.hasNextPage;
            this.hasPreviousPage = resp.data.data.searchReservesDate.pageInfo.hasPreviousPage;
          }
          this.startCursor = resp.data.data.searchReservesDate.pageInfo.startCursor;
          this.endCursor = resp.data.data.searchReservesDate.pageInfo.endCursor;
          this.items = resp.data.data.searchReservesDate.edges.map(value => {
            return value.node;
          });
        }).catch(err => {
          this.toast.fire({
            icon: "error",
            title: err.message
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    nextPage() {
      this.loading = true;
      searchNextReserves({
        name: this.search,
        after: this.endCursor,
        first: this.cant,
        date: this.search_date
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchReserves.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchReserves.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReserves.pageInfo.endCursor;
        this.items = resp.data.data.searchReserves.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevReserves({
        name: this.search,
        before: this.startCursor,
        first: this.cant,
        date: this.search_date
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.searchReserves.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchReserves.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReserves.pageInfo.endCursor;
        this.items = resp.data.data.searchReserves.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    minDateValidate() {
      return this.fecha_salida ? new Date(new Date(this.fecha_salida).getTime() + 86400000) : new Date(Date.now() + 86400000)
    },
    servicesExtraMount() {
      let cost = 0;
      this.servicios_extras.forEach(e => {
        const r = this.serviciosExtrasApp.find(f => e == f.id);
        cost += r ? parseFloat(r.precio) : 0;
      });
      return cost;
    },
    dateVenc() {
      if (!this.fecha_tarjeta) {
        return null
      }
      if (typeof (this.fecha_tarjeta) === "object") {
        if (this.fecha_tarjeta.year) {
          d = new Date(this.fecha_tarjeta.year, this.fecha_tarjeta.month - 1);
        } else {
          d = this.fecha_tarjeta
        }
        const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
        return `${d.getFullYear()}-${month}-${day}`;
      }
      let d = null;
      if (typeof (this.fecha_tarjeta) === 'string') {
        d = new Date(this.fecha_tarjeta);
      } else {
        d = new Date(this.fecha_tarjeta.year, this.fecha_tarjeta.month - 1);
      }
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
      return `${d.getFullYear()}-${month}-${day}`;
    }
  },
  mounted() {
    this.loadDatas(true);
    getClientForReserve().then(resp => {
      this.clientesByName = resp.data.data.clientesByName;
    }).catch(err => console.log(err));
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total {
  background-color: #d1fab3;
  color: #217005;
  border-color: #217005;
  border-radius: 15px;
  padding: 10px
}

.modal-print {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: scroll;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-icon-print {
  position: absolute;
  top: 15px;
  right: 240px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 70%;
}

.modal-content1 {
  padding: 20px;
  width: 40%;
}

.modal-content-print {
  padding: 60px;
  margin-top: 10px;
  width: 70%;
  margin-top: 9999999px;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal1 {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal-print {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.modal1.show {
  opacity: 1;
}

.modal-print.show {
  opacity: 1;
}

.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.img-cliente {
  height: 250px;
  width: 250px;
  object-fit: cover;
}

.steps-container {
  display: flex;
  align-items: center;
}

.step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(10, 10, 10, 0.60);
  box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.44);
}

.active {
  background-color: #1f1f1f;
  color: #fff;
}

.dp__theme_dark {
  --dp-background-color: #2b2a3f;
}

.selected-items {
  display: flex;
  margin-top: 10px;
}

.selected-item {
  background-color: lightgray;
  border: 1px solid gray;
  margin: 5px;
  padding: 5px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.selected-item span {
  margin-right: 5px;
  color: #0d0c1d;
}

.remove-item {
  cursor: pointer;
  margin-left: 5px;
  color: #0d0c1d;
}

.w-321 {
  width: 322px
}
</style>
