<template>
  <BreadCrumb PageTitle="Expiración de Seguro" Subtitle="Reportes"></BreadCrumb>
  <CarExpireSecureComponent></CarExpireSecureComponent>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import MarcaList from "@/components/Nomencladores/MarcaList.vue";
import CarExpireSecureComponent from "@/components/Reports/CarExpireSecureComponent.vue";

export default {
  name: "ExpiracionSecure",
  components: {BreadCrumb, MarcaList, CarExpireSecureComponent}
}
</script>

<style scoped>

</style>
