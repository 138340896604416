<template>
  <div class="Cargando">
    <div v-show="loading || loadingDif" :class="['modal_cargando', { 'show': loading || cargando }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="()=>loadDatas(true)">
          <input
              type="text" v-model="search"
              @keyup.enter="()=>loadDatas(true)"
              @change="()=>loadDatas(true)"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar cliente"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">

      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Foto de cliente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              ID
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Apellidos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Email
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in clientesByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              <div class="ms-3" v-if="item.photo!==''">
                <img class="rounded-circle img-categoria cursor-pointer"
                     :src="getImageUrl(item.photo)"></div>
              <div class="ms-3" v-else>
                <img class="rounded-circle img-categoria cursor-pointer"
                     src="@/assets/images/clientes/no_photo.png"></div>
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearNumero(decodeIDTable(item.id)) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.apellidos }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.user ? item.user.email : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button data-bs-toggle="tooltip"
                        style="padding: 0.8rem"
                        data-bs-placement="top" title="Autorizar cliente" data-container="body"
                        data-animation="true" class="btn text-success" @click="Authorize(item.id)">
                  <i class="flaticon-draw-check-mark d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Details(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver detalles del cliente" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
        <p class="me-3" style="margin-bottom: 0px">Elementos por p&aacute;gina</p>
        <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant"
                @change="loadDatas(true)">
          <option selected :value=10>10</option>
          <option :value=50>50</option>
          <option :value=100>100</option>
        </select>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
        <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage" :disabled="!hasPreviousPage"><i
            class="flaticon-chevron-1 "></i></button>
        <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="!hasNextPage"><i
            class="flaticon-chevron"></i></button>
      </div>
    </div>
  </div>

  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles del cliente
            <i @click="close_Details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-show="pagina===0" class="row mb-1 text-start">
          <h5>Datos personales</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Tratamiento:</h6>
            <p>{{ item.tratamiento }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Nombre:</h6>
            <p>{{ item.nombre }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Apellidos:</h6>
            <p>{{ item.apellidos }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de nacimiento:</h6>
            <p>{{ item.nacimiento }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Nacionalidad:</h6>
            <p>{{ item.nacionalidad }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>N&uacute;mero de tarjeta:</h6>
            <p>{{ item.tarjetaSet.length > 0 ? item.tarjetaSet[0].numero : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Autorizaci&oacute;n de la tarjeta:</h6>
            <p>{{ item.tarjetaSet.length > 0 ? item.tarjetaSet[0].autorizacion : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Vencimiento de la tarjeta:</h6>
            <p>{{ item.tarjetaSet.length > 0 ? item.tarjetaSet[0].vencimiento : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===0" class="row mb-3 text-start">
          <h5>Documento de identidad</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>País de expedici&oacute;n:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].pais : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Tipo de documento:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].tipo : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>No. documento:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].numero : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de expedici&oacute;n:</h6>
            <p>{{ item.identidadSet.length > 0 ? item.identidadSet[0].expedicion : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===0" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Details" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="pagina=1" class="btn btn-danger d-block w-100" type="button">
              Siguiente
            </button>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-1 text-start">
          <h5>Licencia de conducci&oacute;n</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>No. licencia:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].numero : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Clase:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].clase : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de otorgamiento:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].otorgamiento : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Fecha de vencimiento:</h6>
            <p>{{ item.licenciaSet.length > 0 ? item.licenciaSet[0].vencimiento : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-1 text-start">
          <h5>Direcci&oacute;n</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Pa&iacute;s:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].pais : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Ciudad:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].ciudad : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Provincia:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].estado : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>C&oacute;digo postal:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].postal : "" }}</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h6>Direcci&oacute;n:</h6>
            <p>{{ item.direccionSet.length > 0 ? item.direccionSet[0].direccion : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-3 text-start">
          <h5>Datos de contacto</h5>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Tel&eacute;fono:</h6>
            <p>{{ item.contactoSet.length > 0 ? item.contactoSet[0].telefono : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Idioma:</h6>
            <p>{{ item.contactoSet.length > 0 ? item.contactoSet[0].idioma : "" }}</p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <h6>Email:</h6>
            <p>{{ item.user ? item.user.email : "" }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="pagina=0" class="btn btn-secondary d-block w-100"
                    type="button">Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="pagina=2" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
          </div>
        </div>
        <div v-show="pagina===2" class="row mb-3 text-start">
          <h5>Im&aacute;genes de documento de identidad</h5>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Frente:</h6>
            <div v-if="this.item.identidadSet.length>0 && this.item.identidadSet[0].imagenFront!==''">
              <a target="_blank" v-show="this.item.identidadSet[0].imagenFront!==''"
                 :href="this.item.identidadSet.length>0?getImageUrl(this.item.identidadSet[0].imagenFront):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del frente" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Dorso:</h6>
            <div v-if="this.item.identidadSet.length>0 && this.item.identidadSet[0].imagenBack!==''">
              <a target="_blank" v-show="this.item.identidadSet[0].imagenBack!==''"
                 :href="this.item.identidadSet.length>0?getImageUrl(this.item.identidadSet[0].imagenBack):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del dorso" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
        </div>
        <div v-show="pagina===2" class="row mb-3 text-start">
          <h5>Im&aacute;genes de licencia de conducci&oacute;n</h5>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Frente:</h6>
            <div v-if="this.item.licenciaSet.length>0 && this.item.licenciaSet[0].imagenFront!==''">
              <a target="_blank" v-show="this.item.licenciaSet[0].imagenFront!==''"
                 :href="this.item.licenciaSet.length>0?getImageUrl(this.item.licenciaSet[0].imagenFront):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <h6>Dorso:</h6>
            <div v-if="this.item.licenciaSet.length>0 && this.item.licenciaSet[0].imagenBack!==''">
              <a target="_blank" v-show="this.item.licenciaSet[0].imagenBack!==''"
                 :href="this.item.licenciaSet.length>0?getImageUrl(this.item.licenciaSet[0].imagenBack):''"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del dorso" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
        </div>
        <div v-show="pagina===2" class="row mb-3 text-start">
          <h5>Imagen del cliente</h5>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div v-if="item.photoAdmin !==''">
              <a target="_blank"
                 :href="getImageUrl(this.item.photoAdmin)"
                 data-bs-toggle="tooltip" data-bs-placement="top"
                 title="Descargar imagen del cliente" data-container="body" data-animation="true"
                 class="btn btn-danger btn-sm p-1 ms-1">
                Descargar</a>
            </div>
            <div v-else>
              No tiene imagen
            </div>
          </div>
        </div>
        <div v-show="pagina===2" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="pagina=1" class="btn btn-secondary d-block w-100"
                    type="button">Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="close_Details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import {
  searchClients,
  searchPrevClients,
  searchNextClients,
  searchClientById,
  getCountriesByName,
  getCitiesByName,
  getStatesByName,
  clientPoint,
  disableClient,
  enableClient,
  createClient,
  updateClient,
  fiscalInformationClient,
  getInfoInvoiceClient,
  clientePhoto,
  clienteLicencia,
  clienteIdentidad,
  setClientPassword, searchClientsAuth, searchNextClientsAuth, searchPrevClientsAuth, AuthorizeClient
} from "@/actions";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import {decodeBase64} from "@/util";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {srac_backend_url, srac_url_image} from "@/actions/constants";
import {fiscalRegimen} from "@/util/validation";

const auxInvoice = {
  client: "",
  colonia: "",
  rfc: "",
  socialReason: "",
  municipality: "",
  fiscalAddress: "",
  stateInvoice: "",
  emailInvoice: "",
  person: "",
  postalCodeInvoice: "",
  fiscalRegimen: ""
};

export default {
  name: "ClienteList",
  components: {
    MazPhoneNumberInput,
    VueGoogleAutocomplete
  },
  data() {
    return {
      Security: {
        password: "",
        password1: "",
        email: "",
        error: false,
        Security_Modal: false,
      },

      objInvoice: Object.assign({}, auxInvoice),
      dialogInvoice: false,
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      apiKey: process.env.VUE_APP_API_KEY,
      lastStartCursor: "",
      endCursor: "",
      regimenFiscalValidation: fiscalRegimen,
      lastEndCursor: "",

      clientesByName: [],
      paisesByName: [],
      ciudadesByName: [],
      estadosByName: [],
      cliente_id: 0,
      apellidos: "",
      direccion: "",
      direccionCiudad: 0,
      email: "",
      rfc: "",
      fechaNacimiento: null,
      identidadExpedicion: null,
      identidadNumero: "",
      identidadPais: 0,
      identidadTipo: "",
      licenciaClase: "",
      licenciaOtorgamiento: null,
      licenciaVencimiento: null,
      licenciaNumero: "",
      nacionalidad: "",
      nombre: "",
      postal: "",
      telefono: "",
      tratamiento: "",
      idioma: "",
      error_vacios: false,
      error_nacimiento: false,
      error_expiracion: false,
      error_expedicion: false,
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      direccionPais: 0,
      direccionEstado: 0,
      ventana: 0,
      cant: 10,
      search: '',
      Files_Modal: false,
      photo_carnet_front: null,
      photo_carnet_back: null,
      photo_licencia_front: null,
      photo_licencia_back: null,
      photo_tarjeta_front: null,
      photo_tarjeta_back: null,
      accion: '',
      longitudDeseada: 6,
      relleno: '0',
      numeroFormateado: '',
      pagina: 0,
      Details_Modal: false,
      item: {
        id: null,
        tratamiento: null,
        nombre: null,
        apellidos: null,
        nacionalidad: null,
        nacimiento: null,
        isActive: null,
        photo: null,
        photoAdmin: null,
        user: null,
        direccionSet: [],
        identidadSet: [],
        licenciaSet: [],
        contactoSet: [],
        tarjetaSet: []
      },
      fullNameClient: "",
      tarjeta_autorizacion: '',
      tarjeta_numero: '',
      tarjeta_vencimiento: null,
      cliente_photo: null,
      preview: null,
      Photo_Modal: false,
      cargando: false,
      Points_Modal: false,
      points: 0,
      codigo: "",
      loadingDif: false,
      error_telefono: true,
      loading: true,
      saveChanges: false,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  created() {
    const q = this.$route.query["client"]
    if (q) {
      this.loadingDif = true
      searchClientById(q).then(resp => {
        this.getInfo({
          ...resp.data.data.searchClientById,
          loadingInfo: true,
        });
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loadingDif = false;
      })
    }
  },
  watch: {
    telefono(value) {
      if (value !== '') {
        this.error_telefono = false
      }
    },
    clientesByName() {
      this.$store.state.Cargando = false
    },

    search() {
      this.loadDatas(true)
    }
  },
  methods: {
    decodeIDTable(id) {
      return decodeBase64(id)
    },

    loadDatas(init = false) {
      this.loading = true;
      let next = '';
      let before = '';
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      searchClientsAuth({
        before: before,
        after: next,
        cant: this.cant,
        name: this.search
      }).then((resp) => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchClientsAuth.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchClientsAuth.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchClientsAuth.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchClientsAuth.pageInfo.endCursor;
        this.clientesByName = resp.data.data.searchClientsAuth.edges.map(value => {
          return {
            ...value.node,
            loadingInfo: false,
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      searchNextClientsAuth({
        after: this.endCursor,
        cant: this.cant,
        name: this.search
      }).then((resp) => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchClientsAuth.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchClientsAuth.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchClientsAuth.pageInfo.endCursor;
        this.clientesByName = resp.data.data.searchClientsAuth.edges.map(value => {
          return {
            ...value.node,
            loadingInfo: false,
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevClientsAuth({
        before: this.startCursor,
        cant: this.cant,
        name: this.search
      }).then((resp) => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchClientsAuth.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchClientsAuth.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchClientsAuth.pageInfo.endCursor;
        this.clientesByName = resp.data.data.searchClientsAuth.edges.map(value => {
          return {
            ...value.node,
            loadingInfo: false,
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },

    Details(id) {
      this.loading = true
      searchClientById(id).then(resp => {
        const aux = resp.data.data.searchClientById;
        this.item = {
          id: aux.id,
          tratamiento: aux.tratamiento,
          nombre: aux.nombre,
          apellidos: aux.apellidos,
          nacionalidad: aux.nacionalidad,
          nacimiento: aux.nacimiento,
          isActive: aux.isActive,
          photo: aux.photo,
          photoAdmin: aux.photoAdmin,
          user: aux.user,
          rfc: aux.rfc,
          direccionSet: aux.direccionSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node
          }),
          identidadSet: aux.identidadSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node
          }),
          licenciaSet: aux.licenciaSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
          contactoSet: aux.contactoSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
          tarjetaSet: aux.tarjetaSet.edges.map(e => {
            e.node.id = decodeBase64(e.node.id);
            return e.node;
          }),
        };
        this.Details_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },
    close_Details() {
      this.Details_Modal = false;
      this.pagina = 0;
      this.item = {
        id: null,
        tratamiento: null,
        nombre: null,
        apellidos: null,
        nacionalidad: null,
        nacimiento: null,
        isActive: null,
        photo: null,
        user: null,
        rfc: null,
        direccionSet: [],
        identidadSet: [],
        licenciaSet: [],
        contactoSet: [],
        tarjetaSet: []
      };
    },
    Authorize(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Está seguro que desea autorizar el cliente",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, autorizar!"
      }).then(async (result) => {
        if (result.isDenied) {
          AuthorizeClient(decodeBase64(id)).then(response => {
            if (response.data.data.authorizeClient.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadDatas();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al autorizar al cliente"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    formatearNumero(numero) {
      return "SRAC" + String(numero).padStart(this.longitudDeseada, this.relleno);
    },
  },
  mounted() {
    this.loadDatas(true);
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 60%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.img-cliente {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.icon-loading-custom {
  width: 22px !important;
  height: 22px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
